import classicalFestivalSurabaya1 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya1.jpg";
import classicalFestivalSurabaya2 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya2.jpg";
import classicalFestivalSurabaya3 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya3.jpg";
import classicalFestivalSurabaya4 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya4.jpg";
import classicalFestivalSurabaya5 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya5.jpg";
import classicalFestivalSurabaya6 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya6.jpg";
import classicalFestivalSurabaya7 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya7.jpg";
import classicalFestivalSurabaya8 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya8.jpg";
import classicalFestivalSurabaya9 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya9.jpg";
import classicalFestivalSurabaya10 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya10.jpg";
import classicalFestivalSurabaya11 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya11.jpg";
import classicalFestivalSurabaya12 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya12.jpg";
import classicalFestivalSurabaya13 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya13.jpg";
import classicalFestivalSurabaya14 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya14.jpg";
import classicalFestivalSurabaya15 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya15.jpg";
import classicalFestivalSurabaya16 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya16.jpg";
import classicalFestivalSurabaya17 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya17.jpg";
import classicalFestivalSurabaya18 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya18.jpg";
import classicalFestivalSurabaya19 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya19.jpg";
import classicalFestivalSurabaya20 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya20.jpg";
import classicalFestivalSurabaya21 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya21.jpg";
import classicalFestivalSurabaya22 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya22.jpg";
import classicalFestivalSurabaya23 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya23.jpg";
import classicalFestivalSurabaya24 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya24.jpg";
import classicalFestivalSurabaya25 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya25.jpg";
import classicalFestivalSurabaya26 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya26.jpg";
import classicalFestivalSurabaya27 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya27.jpg";
import classicalFestivalSurabaya28 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya28.jpg";
import classicalFestivalSurabaya29 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya29.jpg";
import classicalFestivalSurabaya30 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya30.jpg";
import classicalFestivalSurabaya31 from "../assets/images/galery/classicalFestivalSurabaya/classicalFestivalSurabaya31.jpg";

export const ClassicalFestivalSurabayaImages = [
    classicalFestivalSurabaya1,
    classicalFestivalSurabaya2,
    classicalFestivalSurabaya3,
    classicalFestivalSurabaya4,
    classicalFestivalSurabaya5,
    classicalFestivalSurabaya6,
    classicalFestivalSurabaya7,
    classicalFestivalSurabaya8,
    classicalFestivalSurabaya9,
    classicalFestivalSurabaya10,
    classicalFestivalSurabaya11,
    classicalFestivalSurabaya12,
    classicalFestivalSurabaya13,
    classicalFestivalSurabaya14,
    classicalFestivalSurabaya15,
    classicalFestivalSurabaya16,
    classicalFestivalSurabaya17,
    classicalFestivalSurabaya18,
    classicalFestivalSurabaya19,
    classicalFestivalSurabaya20,
    classicalFestivalSurabaya21,
    classicalFestivalSurabaya22,
    classicalFestivalSurabaya23,
    classicalFestivalSurabaya24,
    classicalFestivalSurabaya25,
    classicalFestivalSurabaya26,
    classicalFestivalSurabaya27,
    classicalFestivalSurabaya28,
    classicalFestivalSurabaya29,
    classicalFestivalSurabaya31,
    classicalFestivalSurabaya30,
    classicalFestivalSurabaya31
];
