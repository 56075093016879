import soundTrackMovies1 from "../assets/images/galery/soundTrackMovies/soundTrackMovies1.jpg";
import soundTrackMovies2 from "../assets/images/galery/soundTrackMovies/soundTrackMovies2.jpg";
import soundTrackMovies3 from "../assets/images/galery/soundTrackMovies/soundTrackMovies3.jpg";
import soundTrackMovies4 from "../assets/images/galery/soundTrackMovies/soundTrackMovies4.jpg";
import soundTrackMovies5 from "../assets/images/galery/soundTrackMovies/soundTrackMovies5.jpg";
import soundTrackMovies6 from "../assets/images/galery/soundTrackMovies/soundTrackMovies6.jpg";
import soundTrackMovies7 from "../assets/images/galery/soundTrackMovies/soundTrackMovies7.jpg";
import soundTrackMovies8 from "../assets/images/galery/soundTrackMovies/soundTrackMovies8.jpg";
import soundTrackMovies9 from "../assets/images/galery/soundTrackMovies/soundTrackMovies9.jpg";
import soundTrackMovies10 from "../assets/images/galery/soundTrackMovies/soundTrackMovies10.jpg";
import soundTrackMovies11 from "../assets/images/galery/soundTrackMovies/soundTrackMovies11.jpg";
import soundTrackMovies12 from "../assets/images/galery/soundTrackMovies/soundTrackMovies12.jpg";
import soundTrackMovies13 from "../assets/images/galery/soundTrackMovies/soundTrackMovies13.jpg";
import soundTrackMovies14 from "../assets/images/galery/soundTrackMovies/soundTrackMovies14.jpg";
import soundTrackMovies15 from "../assets/images/galery/soundTrackMovies/soundTrackMovies15.jpg";
import soundTrackMovies16 from "../assets/images/galery/soundTrackMovies/soundTrackMovies16.jpg";
import soundTrackMovies17 from "../assets/images/galery/soundTrackMovies/soundTrackMovies17.jpg";
import soundTrackMovies18 from "../assets/images/galery/soundTrackMovies/soundTrackMovies18.jpg";
import soundTrackMovies19 from "../assets/images/galery/soundTrackMovies/soundTrackMovies19.jpg";
import soundTrackMovies20 from "../assets/images/galery/soundTrackMovies/soundTrackMovies20.jpg";
import soundTrackMovies21 from "../assets/images/galery/soundTrackMovies/soundTrackMovies21.jpg";
import soundTrackMovies22 from "../assets/images/galery/soundTrackMovies/soundTrackMovies22.jpg";
import soundTrackMovies23 from "../assets/images/galery/soundTrackMovies/soundTrackMovies23.jpg";
import soundTrackMovies24 from "../assets/images/galery/soundTrackMovies/soundTrackMovies24.jpg";
import soundTrackMovies25 from "../assets/images/galery/soundTrackMovies/soundTrackMovies25.jpg";
import soundTrackMovies26 from "../assets/images/galery/soundTrackMovies/soundTrackMovies26.jpg";
import soundTrackMovies27 from "../assets/images/galery/soundTrackMovies/soundTrackMovies27.jpg";
import soundTrackMovies28 from "../assets/images/galery/soundTrackMovies/soundTrackMovies28.jpg";
import soundTrackMovies29 from "../assets/images/galery/soundTrackMovies/soundTrackMovies29.jpg";
import soundTrackMovies30 from "../assets/images/galery/soundTrackMovies/soundTrackMovies30.jpg";
import soundTrackMovies31 from "../assets/images/galery/soundTrackMovies/soundTrackMovies31.jpg";
import soundTrackMovies32 from "../assets/images/galery/soundTrackMovies/soundTrackMovies32.jpg";
import soundTrackMovies33 from "../assets/images/galery/soundTrackMovies/soundTrackMovies33.jpg";
import soundTrackMovies34 from "../assets/images/galery/soundTrackMovies/soundTrackMovies34.jpg";
import soundTrackMovies35 from "../assets/images/galery/soundTrackMovies/soundTrackMovies35.jpg";

export const MagicalMusicSoundtractImages = [
    soundTrackMovies1,
    soundTrackMovies2,
    soundTrackMovies3,

    soundTrackMovies9,

    soundTrackMovies5,
    soundTrackMovies6,
    soundTrackMovies7,
    soundTrackMovies8,

    soundTrackMovies4,
    soundTrackMovies10,
    soundTrackMovies11,
    soundTrackMovies12,
    soundTrackMovies13,
    soundTrackMovies14,
    soundTrackMovies15,
    soundTrackMovies16,
    soundTrackMovies17,
    soundTrackMovies18,
    soundTrackMovies19,
    soundTrackMovies20,
    soundTrackMovies21,
    soundTrackMovies22,
    soundTrackMovies23,
    soundTrackMovies24,
    soundTrackMovies25,
    soundTrackMovies26,
    soundTrackMovies27,
    soundTrackMovies28,
    soundTrackMovies29,
    soundTrackMovies30,
    soundTrackMovies31,
    soundTrackMovies32,
    soundTrackMovies33,
    soundTrackMovies35,
    soundTrackMovies34,
];
