import turningPoint1 from "../assets/images/galery/turningPoint/turningpoint1.png";
import turningPoint2 from "../assets/images/galery/turningPoint/turningpoint2.png";
import turningPoint3 from "../assets/images/galery/turningPoint/turningpoint3.png";
import turningPoint4 from "../assets/images/galery/turningPoint/turningpoint4.png";
import turningPoint5 from "../assets/images/galery/turningPoint/turningpoint5.png";
import turningPoint6 from "../assets/images/galery/turningPoint/turningpoint6.png";
import turningPoint7 from "../assets/images/galery/turningPoint/turningpoint7.png";
import turningPoint8 from "../assets/images/galery/turningPoint/turningpoint8.png";
import turningPoint9 from "../assets/images/galery/turningPoint/turningpoint9.png";
import turningPoint10 from "../assets/images/galery/turningPoint/turningpoint10.png";
import turningPoint11 from "../assets/images/galery/turningPoint/turningpoint11.png";
import turningPoint12 from "../assets/images/galery/turningPoint/turningpoint12.png";
import turningPoint13 from "../assets/images/galery/turningPoint/turningpoint13.png";
import turningPoint14 from "../assets/images/galery/turningPoint/turningpoint14.png";
import turningPoint15 from "../assets/images/galery/turningPoint/turningpoint15.png";
import turningPoint16 from "../assets/images/galery/turningPoint/turningpoint16.png";
import turningPoint17 from "../assets/images/galery/turningPoint/turningpoint17.png";
import turningPoint18 from "../assets/images/galery/turningPoint/turningpoint18.png";
import turningPoint19 from "../assets/images/galery/turningPoint/turningpoint19.png";
import turningPoint20 from "../assets/images/galery/turningPoint/turningpoint20.png";
import turningPoint21 from "../assets/images/galery/turningPoint/turningpoint21.png";
import turningPoint22 from "../assets/images/galery/turningPoint/turningpoint22.png";
import turningPoint23 from "../assets/images/galery/turningPoint/turningpoint23.png";
import turningPoint24 from "../assets/images/galery/turningPoint/turningpoint24.png";
import turningPoint25 from "../assets/images/galery/turningPoint/turningpoint25.png";
import turningPoint26 from "../assets/images/galery/turningPoint/turningpoint26.png";
import turningPoint27 from "../assets/images/galery/turningPoint/turningpoint27.png";
import turningPoint28 from "../assets/images/galery/turningPoint/turningpoint28.png";
import turningPoint29 from "../assets/images/galery/turningPoint/turningpoint29.png";
import turningPoint30 from "../assets/images/galery/turningPoint/turningpoint30.png";
import turningPoint31 from "../assets/images/galery/turningPoint/turningpoint31.png";
import turningPoint32 from "../assets/images/galery/turningPoint/turningpoint32.png";
import turningPoint33 from "../assets/images/galery/turningPoint/turningpoint33.png";
import turningPoint34 from "../assets/images/galery/turningPoint/turningpoint34.png";

export const TurningPointImages = [
    turningPoint1,
    turningPoint2,
    turningPoint3,
    turningPoint4,
    turningPoint5,
    turningPoint6,
    turningPoint7,
    turningPoint8,
    turningPoint9,
    turningPoint10,
    turningPoint11,
    turningPoint12,
    turningPoint13,
    turningPoint14,
    turningPoint15,
    turningPoint16,
    turningPoint17,
    turningPoint18,
    turningPoint19,
    turningPoint20,
    turningPoint21,
    turningPoint22,
    turningPoint23,
    turningPoint24,
    turningPoint25,
    turningPoint26,
    turningPoint27,
    turningPoint30,
    turningPoint29,
    turningPoint28,
    turningPoint31,
    turningPoint32,
    turningPoint33,
    turningPoint34,
    turningPoint34,
];
