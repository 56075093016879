import christmasInWonderland1 from "../assets/images/galery/christmasInWonderland/christmasInWonderland1.jpg";
import christmasInWonderland2 from "../assets/images/galery/christmasInWonderland/christmasInWonderland2.jpg";
import christmasInWonderland3 from "../assets/images/galery/christmasInWonderland/christmasInWonderland3.jpg";
import christmasInWonderland4 from "../assets/images/galery/christmasInWonderland/christmasInWonderland4.jpg";
import christmasInWonderland5 from "../assets/images/galery/christmasInWonderland/christmasInWonderland5.jpg";
import christmasInWonderland6 from "../assets/images/galery/christmasInWonderland/christmasInWonderland6.jpg";
import christmasInWonderland7 from "../assets/images/galery/christmasInWonderland/christmasInWonderland7.jpg";
import christmasInWonderland8 from "../assets/images/galery/christmasInWonderland/christmasInWonderland8.jpg";
import christmasInWonderland9 from "../assets/images/galery/christmasInWonderland/christmasInWonderland9.jpg";
import christmasInWonderland10 from "../assets/images/galery/christmasInWonderland/christmasInWonderland10.jpg";
import christmasInWonderland11 from "../assets/images/galery/christmasInWonderland/christmasInWonderland11.jpg";
import christmasInWonderland12 from "../assets/images/galery/christmasInWonderland/christmasInWonderland12.jpg";
import christmasInWonderland13 from "../assets/images/galery/christmasInWonderland/christmasInWonderland13.jpg";
import christmasInWonderland14 from "../assets/images/galery/christmasInWonderland/christmasInWonderland14.jpg";
import christmasInWonderland15 from "../assets/images/galery/christmasInWonderland/christmasInWonderland15.jpg";
import christmasInWonderland16 from "../assets/images/galery/christmasInWonderland/christmasInWonderland16.jpg";
import christmasInWonderland17 from "../assets/images/galery/christmasInWonderland/christmasInWonderland17.jpg";
import christmasInWonderland18 from "../assets/images/galery/christmasInWonderland/christmasInWonderland18.jpg";
import christmasInWonderland19 from "../assets/images/galery/christmasInWonderland/christmasInWonderland19.jpg";
import christmasInWonderland20 from "../assets/images/galery/christmasInWonderland/christmasInWonderland20.jpg";
import christmasInWonderland21 from "../assets/images/galery/christmasInWonderland/christmasInWonderland21.jpg";
import christmasInWonderland22 from "../assets/images/galery/christmasInWonderland/christmasInWonderland22.jpg";
import christmasInWonderland23 from "../assets/images/galery/christmasInWonderland/christmasInWonderland23.jpg";
import christmasInWonderland24 from "../assets/images/galery/christmasInWonderland/christmasInWonderland24.jpg";
import christmasInWonderland25 from "../assets/images/galery/christmasInWonderland/christmasInWonderland25.jpg";
import christmasInWonderland26 from "../assets/images/galery/christmasInWonderland/christmasInWonderland26.jpg";
import christmasInWonderland27 from "../assets/images/galery/christmasInWonderland/christmasInWonderland27.jpg";
import christmasInWonderland28 from "../assets/images/galery/christmasInWonderland/christmasInWonderland28.jpg";
import christmasInWonderland29 from "../assets/images/galery/christmasInWonderland/christmasInWonderland29.jpg";
import christmasInWonderland30 from "../assets/images/galery/christmasInWonderland/christmasInWonderland30.jpg";
import christmasInWonderland31 from "../assets/images/galery/christmasInWonderland/christmasInWonderland31.jpg";
import christmasInWonderland32 from "../assets/images/galery/christmasInWonderland/christmasInWonderland32.jpg";
import christmasInWonderland33 from "../assets/images/galery/christmasInWonderland/christmasInWonderland33.jpg";
import christmasInWonderland34 from "../assets/images/galery/christmasInWonderland/christmasInWonderland34.jpg";

export const ChristmasInWonderlandImages = [
    christmasInWonderland1,
    christmasInWonderland2,
    christmasInWonderland3,
    christmasInWonderland4,
    christmasInWonderland5,
    christmasInWonderland6,
    christmasInWonderland7,
    christmasInWonderland8,
    christmasInWonderland9,
    christmasInWonderland10,
    christmasInWonderland11,
    christmasInWonderland12,
    christmasInWonderland13,
    christmasInWonderland14,
    christmasInWonderland15,
    christmasInWonderland16,
    christmasInWonderland17,
    christmasInWonderland18,
    christmasInWonderland19,
    christmasInWonderland20,
    christmasInWonderland21,
    christmasInWonderland22,
    christmasInWonderland24,
    christmasInWonderland23,
    christmasInWonderland25,
    christmasInWonderland26,
    christmasInWonderland27,
    christmasInWonderland28,
    christmasInWonderland29,
    christmasInWonderland30,
    christmasInWonderland31,
    christmasInWonderland32,
    christmasInWonderland33,
    christmasInWonderland34,
    christmasInWonderland34,
];
