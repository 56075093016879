import masterClass1 from "../assets/images/galery/masterClass/masterClass1.jpg";
import masterClass2 from "../assets/images/galery/masterClass/masterClass2.jpg";
import masterClass3 from "../assets/images/galery/masterClass/masterClass3.jpg";
import masterClass4 from "../assets/images/galery/masterClass/masterClass4.jpg";
import masterClass5 from "../assets/images/galery/masterClass/masterClass5.jpg";
import masterClass6 from "../assets/images/galery/masterClass/masterClass6.jpg";
import masterClass7 from "../assets/images/galery/masterClass/masterClass7.jpg";
import masterClass8 from "../assets/images/galery/masterClass/masterClass8.jpg";
import masterClass9 from "../assets/images/galery/masterClass/masterClass9.jpg";
import masterClass10 from "../assets/images/galery/masterClass/masterClass10.jpg";
import masterClass11 from "../assets/images/galery/masterClass/masterClass11.jpg";
import masterClass12 from "../assets/images/galery/masterClass/masterClass12.jpg";
import masterClass13 from "../assets/images/galery/masterClass/masterClass13.jpg";
import masterClass14 from "../assets/images/galery/masterClass/masterClass14.jpg";
import masterClass15 from "../assets/images/galery/masterClass/masterClass15.jpg";
import masterClass16 from "../assets/images/galery/masterClass/masterClass16.jpg";
import masterClass17 from "../assets/images/galery/masterClass/masterClass17.jpg";
import masterClass18 from "../assets/images/galery/masterClass/masterClass18.jpg";
import masterClass19 from "../assets/images/galery/masterClass/masterClass19.jpg";
import masterClass20 from "../assets/images/galery/masterClass/masterClass20.jpg";
import masterClass21 from "../assets/images/galery/masterClass/masterClass21.jpg";
import masterClass22 from "../assets/images/galery/masterClass/masterClass22.jpg";

export const MasterClassImages = [
    masterClass1,
    masterClass2,
    masterClass3,
    masterClass4,
    masterClass5,
    masterClass6,
    masterClass7,
    masterClass8,
    masterClass9,
    masterClass10,
    masterClass11,
    masterClass12,
    masterClass13,
    masterClass14,
    masterClass15,
    masterClass16,
    masterClass17,
    masterClass18,
    masterClass19,
    masterClass20,
    masterClass21,
    masterClass22,
    masterClass22,
];